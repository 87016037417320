.App {
  text-align: center;
  overflow-x: hidden;
}
 
.AllPage{
  margin-top: 100px;
}
@media(max-width:990px){
  .AllPage{
    margin-top: 75px;
  }
  .App {
    text-align: center;
    overflow-x: hidden !important;
  }
}
 
 
