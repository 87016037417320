 a{
    text-decoration: none;
    color: gray;
 }
.anibtn{
    position: relative;
    border: none;
    background: none;
    font-weight: 600;
    padding: 5px;
}
.anibtn::after{
    content: '';
    position: absolute;
    width: 50%;
    height: 3px;
    left: 0;
    bottom: 0;
    background: #ea0e4b;padding: 0;
}

.anibtn-left::after {
     transform: scale(0,1);
     transform-origin: 0% 100%;
     transition: transform 0.3s ease;
}
.anibtn-left:hover::after{
    transform: scale(1,1); 
}
.anibtn-left .anibtn-left-after{
    transform: scale(1,1); 
}

.active > span{
     border-bottom: 3px solid red;
}
.decktopNav{
    display: block;
}
.mobileNav{
    display: none;
}
#button{
    background: #215395;
    border-radius: 20px;
    font-weight: 600;
    color: white;
    padding: 9px 20px; 
}
#button:hover{
    background: #002f6c;
    border-radius: 20px;
    font-weight: 600;
    color: white;
    padding: 9px 20px; 
}

#Readbutton{
    background:#FED217;
    border-radius: 20px;
    font-weight: 600;
    color: black;
    padding: 9px 20px; 
}
#Readbutton:hover{
    background: #dfb600; 
}
.navbarMain{ 
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2; 
    height: auto;   
    transition: 300ms; 
}
.navbarMain--hidden{
    transform: translateY(-127px);
    transition: 2000ms; 
}
/* home start  */
 
.topSlider{
    height:90vh;
    background-size: 100% 100%; 
    display: grid;
    align-items: center;
}
.readMore{
    background: #FED217;
}
.homeTopImg{
    width: 95% !important;
   
}
 
.homTopSlider .carousel.carousel-slider .control-arrow{
    background: gray !important;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    padding:-20px 0 0 0  ;
    margin: 5px;
}
.homTopSlider .carousel.carousel-slider .control-arrow:hover{
    background: #FED217 !important; 
}
.homTopSlider .carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
    margin: 5px !important;
}
.homTopSlider .carousel .control-prev.control-arrow {
    top: 85%;
    left: 40%;
}
.homTopSlider .carousel .control-next.control-arrow {
    top: 85%;
    left: 40%;
    margin-left: 80px;
}
.homTopSlider .carousel .control-dots {
    /* left: 25% !important; */
    display: none;
}
.homTopSlider .carousel .thumbs-wrapper{
    display: none;
}
 .carousel .control-prev.control-arrow {
    z-index: 1 !important; 
}
 .carousel .control-next.control-arrow {
    z-index: 1 !important;  
}
#galleryLarg{
    display: block;
}
#gallerySmall{
    display: none;
}
 
.testimonials .carousel.carousel-slider .control-arrow{
    background: gray !important;
    height: 40px;
    width: 40px;
    border-radius: 50px;
    padding:-20px 0 0 0  ;
    margin: 5px;
}
.testimonials .carousel.carousel-slider .control-arrow:hover{
    background: #FED217 !important; 
}
.testimonials .carousel .control-prev.control-arrow {
    top: 40%; 
}
.testimonials .carousel .control-next.control-arrow {
    top: 40%; 
}
.testiHide{
    display: block;
}
.homeTopCard {
    background-color:white;
    color: black;
}
.homeTopCard:hover {
     background-color:#30B74F !important ;
     color: white;
}
.HomeCards >svg>path{
    /* fill: #215395; */
}
.homeTopCard:hover .HomeCards >svg>path{
    fill: white; 
}
/* Infrastructure */
.infraBtnDiv{
    display: flex;
    justify-content: space-between;
    overflow-x: auto; 
    color: gray;
    width: 100%;
}
.infraBtn{
    cursor: pointer;
    background: none;
    padding:10px 20px;
    border: none; 
    width:250px;
    
}
.enquirySection{
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 20%; 
    height: fit-content;
}
.uniform{
    display: grid;
    grid-template-columns: 50% 50%;
    gap:2px;
}
@media(max-width:1240px) {
    #galleryLarg{
        display: none;
    }
    #gallerySmall{
        display: block;
    }
}

@media(max-width:990px){
    .decktopNav{
        display: none;
    }
    .mobileNav{
        display: block;
    }
    .mainNav{
        border-bottom: 0.5px solid gray; 
    }
    /* home start  */
    .topSlider{
        height:70vh;
        background-size: cover;
        display: grid;
        align-items: center;
    }
    .homeTopImg{
        width: 100% !important;
    }
    
    .testiHide{
        display: none;
    }
    .enquirySection{
        position: static !important; 
        height: fit-content; 
    }
}

@media(max-width:750px){
    .uniform{
        display: grid;
        grid-template-columns: 100%;
        gap:2px;
    }
    .homTopSlider .carousel .control-prev.control-arrow {
        top: 85%;
        left:40%;
    }
    .homTopSlider .carousel .control-next.control-arrow {
        top: 85%;
        left: 40%;
        margin-left: 60px;
    }
}
